import httpClient from '../httpClient'

import { formatData } from '../request'
import { encodeRequestParameters } from '../request'

import { homeInformationsFormat } from '../formats'

/**
 * Returns global user informations
 */
export function getHomeInformations(data) {
    data = formatData(homeInformationsFormat, data)
    if (data === false) {
        throw '500'
    }
    const params = encodeRequestParameters(data)
    return httpClient.get('/home', {params})
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Returns global message on login page
 */
export function getHomeMessage(data) {
    const params = encodeRequestParameters(data)
    return httpClient.get('/informations', {params})
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}