<template>
    <div
        class="loader-ring"
        :class="{
            orange: color === 'orange',
            white: color === 'white',
            blue: color === 'blue',
            small: size === 'small',
            normal: size === 'normal'
        }">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        color: {
            type: String,
            default: "orange"
        },
        size: {
            type: String,
            default: "normal"
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.loader-ring {
    display: inline-block;
    position: relative;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
}

.normal {
    width: 80px;
    height: 80px;

    div {
        width: 64px;
        height: 64px;
    }
}

.small {
    width: 40px;
    height: 40px;

    div {
        width: 32px;
        height: 32px;
    }
}

.white div {
    border-color: $white transparent transparent transparent;
}

.orange div {
    border-color: $orange-neutral transparent transparent transparent;
}

.blue div {
    border-color: $blue-neutral transparent transparent transparent;
}

.loader-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.loader-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.loader-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes loader-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

</style>
