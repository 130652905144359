import httpClient from '../httpClient'

// get rights of user
export function getUserRight() {
  return httpClient.get('/droit/user')
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}
